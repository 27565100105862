<template>
  <div class="user">
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="活动状态:" prop="">
                <el-radio-group v-model="queryInfo.activityState" @change="changeSearchangeSearch">
                  <el-radio-button
                    :label="item.id"
                    v-for="(item, index) in InformationTypes"
                    :key="index"
                    >{{ item.value }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="参与方式:" prop="">
                <el-radio-group v-model="queryInfo.activityType" @change="changeSearchangeSearch">
                  <el-radio-button
                    :label="item.id"
                    v-for="(item, index) in ParticipationMode"
                    :key="index"
                    >{{ item.value }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="活动日期:">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="ruleForm.date"
                  @change="checkParamsData"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="活动类型:" prop="">
                <el-radio-group v-model="queryInfo.isPlatform" @change="changeSearchangeSearch">
                  <el-radio-button
                    :label="item.id"
                    v-for="(item, index) in activityType"
                    :key="index"
                    >{{ item.value }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label=" "
                label-width="0px"
                style="border-top: 1px dashed #efefef; padding-top: 20px; margin-bottom: 0"
              >
                <div class="input-with">
                  <div>
                    <el-button type="primary" @click="toPage('activityEditActivity', {})"
                      >新增活动</el-button
                    >
                    <el-button style="margin-right: 5px" @click="SetGeneral"
                      >批量设置智参</el-button
                    >

                    <el-button style="margin-right: 5px" @click="updateOnShelf">批量发布</el-button>
                    <el-button style="margin-right: 5px" @click="updateOffShelf"
                      >批量下架</el-button
                    >
                    <el-button style="margin-right: 5px" @click="checkClick"
                      >设置通用版活动</el-button
                    >

                    <el-checkbox
                      style="position: relative; top: 5px"
                      :indeterminate="isIndeterminate"
                      v-model="checkAll"
                      @change="handleCheckAllChange"
                      >全选</el-checkbox
                    >
                  </div>
                  <div>
                    <el-input
                      placeholder="活动名称、主办方、所在城市"
                      v-model="queryInfo.fuzzyQuery"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <div class="banner_box">
                <div
                  class="activity_state"
                  :class="['not_started', 'have_in_hand', 'closed'][item.activityState - 1]"
                >
                  {{ ['未开始', '进行中', '已结束'][item.activityState - 1] }}
                </div>
                <img v-if="item.activityImg" :src="item.activityImg" alt="" />
                <img v-else src="../../assets/image/Group 3237.png" alt="" />
              </div>
              <div class="storeCenter_item_top_left_flex">
                <div style="display: flex; align-items: center">
                  <h5
                    style="
                      display: inline-block;
                      margin: 0;
                      font-size: 18px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      width: 200px;

                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ item.activityName }}
                  </h5>
                  <span class="generalVersion">
                    {{ ['通用版', '个性版', '全部'][item.generalVersion * 1] }}</span
                  >
                  <div class="mark_box">
                    <div v-if="item.isPlatform == 1">
                      <img src="../../assets/image/Group 3298.png" alt="" /><span>平台活动</span>
                    </div>
                    <div v-else>
                      <img src="../../assets/image/展厅-5 3.png" alt="" /><span>渠道活动</span>
                    </div>
                    <div v-if="item.isMoney">
                      <img src="../../assets/image/Vector-1.png" alt="" /><span>免费</span>
                    </div>
                    <div v-else>
                      <img src="../../assets/image/Vector-1.png" alt="" /><span>付费</span>
                    </div>
                    <div v-if="item.activityType == 1">
                      <img src="../../assets/image/Vector.png" alt="" /><span>线上</span>
                    </div>
                    <div v-else-if="item.activityType == 2">
                      <img src="../../assets/image/Vector(1).png" alt="" /><span>线下</span>
                    </div>
                  </div>
                </div>
                <div class="storeCenter_item_top_left_flex_tag">
                  <span v-for="(o, index) in item.labels" :key="index">
                    <span v-if="index < 2" class="office">{{ o }}</span>
                  </span>
                  <el-popover placement="right" width="430" trigger="hover">
                    <div
                      class="hidden_label_box"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        flex-wrap: wrap;
                      "
                    >
                      <span
                        class="office"
                        style="
                          border: 1px solid #559eff;
                          color: #4e93fb;
                          box-sizing: border-box;
                          padding: 1px 6px;
                          display: block;
                          font-size: 14px;
                          font-weight: 400;
                          border-radius: 4px;
                          margin-right: 10px;
                          margin-bottom: 10px;
                        "
                        v-for="(o, index) in item.labels"
                        :key="index"
                        >{{ o }}</span
                      >
                    </div>

                    <span
                      class="office"
                      slot="reference"
                      v-if="item.labels && item.labels.length >= 2"
                      >{{ item.labels.length }}+</span
                    >
                  </el-popover>
                </div>
                <div class="over_text">{{ item.sponsor }}</div>
                <p class="address">
                  <span style="padding-left: 0px">
                    所在地区： {{ item.activityProvince }}-{{ item.activityCity }}</span
                  >
                  <span> 活动时间： {{ item.activityStartTime }}至{{ item.activityEndTime }}</span>
                </p>
              </div>
            </div>
            <div class="storeCenter_item_top_center">
              <div class="count_box" @click="PushactivityCompany(item)">
                <div>{{ item.pushCompanyNumber }}</div>
                <div>已推送</div>
              </div>
              <div
                class="count_box"
                style="cursor: pointer"
                @click="toPage('accountManagement', item)"
              >
                <div>{{ item.userApplyNum }}</div>
                <div>报名</div>
              </div>
              <div class="count_box" @click="checkCount(item)">
                <div>{{ item.scanUserNumber }}</div>
                <div>查看</div>
              </div>
              <div class="count_box" @click="expertSharing(item)">
                <div>{{ item.shareUserNumber }}</div>
                <div>分享</div>
              </div>
            </div>

            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <el-button type="primary" @click="activityConfig(item, 2)">算法配置</el-button>
                <el-button type="info" plain @click="activityConfig(item, 1)">手动配置</el-button>
                <el-button type="info" @click="clciklabel(item)" plain>标签</el-button>
                <el-button type="info" @click="clcikgensing(item)" plain>配置智参 </el-button>
                <span
                  v-if="item.isShelf == 1 || item.isShelf == null"
                  class="edit"
                  @click="OnShelf(item)"
                  >发布</span
                >
                <span v-if="item.isShelf == 0" class="frozen" @click="OffShelf(item)">下架</span>

                <span class="edit" @click="toPage('activityEditActivity', item)">编辑</span>
                <span v-if="item.isDelete == 0" class="frozen" @click="delcompany(item)">冻结</span>
                <span v-if="item.isDelete == 1" class="frozen" @click="cancelcompany(item)"
                  >激活</span
                >
              </div>
              <div class="details">操作人：{{ item.updateName }}</div>
              <div class="details">最近操作时间：{{ item.updateTime }}</div>
              <div class="po_imgqr" @click="getQRCode(item)">
                <img
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%204010.png"
                  alt=""
                />
              </div>
            </div>
            <div class="pore_qr" @mouseleave="item.qrvisible = !item.qrvisible">
              <el-popover
                placement="left"
                title=""
                width="139"
                height="200"
                trigger="manual"
                v-model="item.qrvisible"
              >
                <div class="qr_top">
                  <span>签到二维码</span>
                </div>
                <div class="popover_img">
                  <img :src="QRCode" alt="" />
                </div>
                <div
                  style="
                    padding: 5px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <el-button plain size="mini" @click="imgClick(item, QRCode)">导出图片</el-button>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <el-dialog
          title="批量配置智参中心"
          :visible.sync="dialogVisible"
          width="627px"
          class="account_dialog"
        >
          <el-form label-width="100px" :model="pageParam" ref="form">
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="pageParam.city" placeholder="请选择地区" @change="domGinsengPage">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中心名称：" prop="link">
              <div class="disinput">
                <el-input
                  placeholder="请输入智参中心名称"
                  v-model="pageParam.queryConditions"
                  class="input-with-select"
                >
                  <el-button
                    style="
                      border: 1px solid #448aff;
                      background-color: #448aff;
                      color: #fff;
                      border-radius: 0;
                    "
                    class="seach"
                    slot="append"
                    @click="domGinsengPage()"
                    >搜索</el-button
                  >
                </el-input>
              </div>
            </el-form-item>
          </el-form>
          <el-table
            ref="multipleTable"
            :data="listgisen"
            stripe
            width="100%"
            class="in_table"
            @selection-change="handleSelectionChange"
            row-key="id"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" align="center" :reserve-selection="true" />
            <el-table-column label="智参中心名称" prop="zcName" align="center"></el-table-column>
            <el-table-column label="所在地区" prop="" align="center">
              <template v-slot="{ row }">
                <span>{{ row.province }}-{{ row.city }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="new_page">
            <el-pagination
              :current-page="pageParam.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="pageParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totaldins"
              @size-change="handleSizeChanges"
              @current-change="handleCurrentChanges"
            />
          </div>
          <div style="text-align: center; margin-top: 40px">
            <el-button type="primary" @click="updatecheckClick()">确 定</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="配置智参中心"
          :visible.sync="dialogVisibles"
          width="700px"
          class="account_dialog"
        >
          <el-form label-width="100px" :model="pageParam" ref="form">
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select
                v-model="pageParam.city"
                placeholder="请选择地区"
                @change="wisdomGinsengPage"
              >
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中心名称：" prop="link">
              <div class="disinput">
                <el-input
                  placeholder="请输入智参中心名称"
                  v-model="pageParam.queryConditions"
                  class="input-with-select"
                >
                  <el-button
                    style="
                      border: 1px solid #448aff;
                      background-color: #448aff;
                      color: #fff;
                      border-radius: 0;
                    "
                    class="seach"
                    slot="append"
                    @click="wisdomGinsengPage()"
                    >搜索</el-button
                  >
                </el-input>
              </div>
            </el-form-item>
            <div class="add_search">
              <div class="left">
                <el-button class="add" @click="isShelfzone">批量上架</el-button>
                <el-button style="margin-right: 5px" @click="isShelfone">批量下架</el-button>
              </div>
            </div>
          </el-form>
          <el-table
            ref="multipleTables"
            v-loading="loading"
            :data="listgisen"
            stripe
            width="100%"
            class="in_table"
            @selection-change="handleSelectionChange"
            row-key="id"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" align="center" :reserve-selection="true" />
            <el-table-column label="智参中心名称" prop="zcName" align="center"></el-table-column>
            <el-table-column label="所在地区" prop="" align="center">
              <template v-slot="{ row }">
                <span>{{ row.province }}-{{ row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作人" prop="userName" align="center"></el-table-column>

            <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>

            <el-table-column label="操作" prop="" align="center">
              <template v-slot="{ row }">
                <span v-if="row.isShelf == 1" @click="isShelfzone(row, 1)" class="frozen"
                  >上架</span
                >
                <span v-if="row.isShelf == 0" @click="isShelfone(row, 1)" class="edit">下架</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="new_page">
            <el-pagination
              :current-page="pageParam.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="pageParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totaldins"
              @size-change="handleSize"
              @current-change="handleCurrent"
            />
          </div>
          <div style="text-align: center; margin-top: 40px">
            <el-button type="primary" @click="dialogVisibles = false">确 定</el-button>
            <el-button @click="dialogVisibles = false">取 消</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  activitylist,
  freezeActivity,
  cancelFreezeActivity,
  updateGeneralVersion,
  updateOffShelf,
  updateOnShelf,
  updateGeneralVersions,
  wisdomGinsengOnSelf,
  wisdomGinsengOffSelf,
  wisdomGinsengReportPage,
  getQRCode
} from '@/api/activity'
import { domGinsengPage } from '../../api/content.js'
import { getByFatherIdt, getProvince } from '@/api/demand'
import { apiDownloadFile } from '@/api/common.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  activityEndTime: '', //活动结束时间
  activityStartTime: '', //活动开始时间
  activityState: '', //活动状态（1：未开始；2：进行中；3：已结束）
  activityType: 0, //活动形式，1线上，2线下
  isPlatform: '', //	是否为平台活动;1是代表平台活动
  orderByParam: '',
  generalVersion: '',
  fuzzyQuery: '' //模糊查询（活动名称、主办方、所在城市）
})
export default {
  name: 'Userment',
  data() {
    return {
      QRCode: '', //签到二维码
      qrvisible: true, //显示签到二维码
      InformationTypes: [
        {
          value: '全部',
          id: null
        },
        {
          value: '未开始',
          id: '1'
        },
        {
          value: '进行中',
          id: '2'
        },

        {
          value: '已结束',
          id: '3'
        }
      ],
      ParticipationMode: [
        {
          value: '全部',
          id: null
        },
        {
          value: '线上',
          id: '1'
        },
        {
          value: '线下',
          id: '2'
        }
      ],
      activityType: [
        {
          value: '全部',
          id: null
        },
        {
          value: '平台活动',
          id: '1'
        },
        {
          value: '渠道活动',
          id: '2'
        }
      ],
      ruleForm: {
        date: null
      },
      //活动时间

      dialogVisible: false, //智参一览
      ids: [],
      idsList: [],
      checkAll: false, //全选
      isIndeterminate: false,
      queryInfo: { ...defaultQueryInfo }, //查询活动一览
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      pageParam: {
        //查询智参中心
        city: null,
        id: null,
        orderByParam: null,
        pageNum: 1,
        pageSize: 10,
        province: null,
        queryConditions: null
      }, //配置智参中心
      totaldins: 0, //智参一览数
      idgisen: [],
      operatingList: [], //城市
      operatingLists: [], //省份
      listgisen: [], //分页查询所有智参一览
      dialogVisibles: false, //配置智参
      bol: false
    }
  },
  // activated() {
  //   if (this.bol) {
  //     this.search()
  //   }
  // },
  created() {
    if (!this.bol) {
      // this.search()
      this.bol = true
    }
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //活动一览
    async search() {
      this.loading = true
      const res = await activitylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'qrvisible', false)
        })
        this.total = res.data.data.total
        this.loading = false
        this.listData.forEach((item) => {
          if (item.labelName != null) {
            item.labelName = item.labelName.split(',')
            item.labelName = item.labelName.slice(0, 3)
          }
        })
      }
    },
    async imageDownload(data) {
      const res = await apiDownloadFile({ urlStr: data })
      if (res.data) {
        const blob = new Blob([res.data])
        const a = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载连接
        a.href = href
        a.download = decodeURI('下载的二维码.png')
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a) // 下载完移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
    },
    //获取签到二维码
    async getQRCode(item) {
      const query = {
        sceneStr: `/pages/activity/activityDetail/index?id=${item.id}&code=${true}`
      }

      const res = await getQRCode(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        this.QRCode = res.data.data
        item.qrvisible = true
      } else {
        this.$message.error('请求失败!')
      }
    },
    //下载二维码
    // imgClick(item, href) {
    //   const a = document.createElement('a') //创建a标签
    //   a.style.display = 'none'
    //   // console.log("二维码",href)
    //   a.href = href // 指定下载'链接
    //   this.QRCode = href
    //   item.qrvisible = true
    //   a.download = `${item.activityName}.png` //指定下载文件名
    //   // a.download = "test.png";
    //   a.click() //触发下载
    //   window.URL.revokeObjectURL(a.href) //释放URL对象
    //   if (document.body.contains(a)) {
    //     document.body.removeChild(a) //释放标签
    //   }
    // },
    imgClick(item, href) {
      this.imageDownload(href)
    },
    // 切换搜索列表的参数
    checkParams(key, val) {
      this.queryInfo[key] = val
      this.search()
    },
    //选择活动时间
    checkParamsData(val) {
      if (val) {
        this.queryInfo.pageNum = 1
        this.queryInfo.activityStartTime = val[0]
        this.queryInfo.activityEndTime = val[1]
      } else {
        this.queryInfo.pageNum = 1
        this.queryInfo.activityStartTime = ''
        this.queryInfo.activityEndTime = ''
      }

      this.search()
    },

    //分页查询智参中心
    async domGinsengPage() {
      delete this.pageParam.id
      const { data: res } = await domGinsengPage(this.pageParam)
      if (res.resultCode == 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
      }
    },
    //批量匹配智参
    updatecheckClick() {
      if (this.ids.length > 0) {
        const query = {
          ids: this.ids,
          schemaNames: this.idgisen
        }
        const that = this
        this.$alert('是否确认设置成通用活动？')
          .then(function () {
            return updateGeneralVersions(query)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.dialogVisible = false
            this.idgisen = []
            this.$refs.multipleTable.clearSelection()
            this.$message.success('设置成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.schemaName)
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    //活动配置智参中心数据一览
    async wisdomGinsengPage() {
      this.listgisen = []
      const { data: res } = await wisdomGinsengReportPage(this.pageParam)
      if (res.resultCode == 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
      }
    },
    //配置智参
    clcikgensing(row) {
      this.pageParam.id = row.id
      this.dialogVisibles = true
      this.wisdomGinsengPage()
      this.incity()
    },
    // 分页活动选择智参中心数据一览
    handleSizeChanges(val) {
      this.pageParam.pageSize = val
      this.domGinsengPage()
    },
    // 分页活动选择智参中心数据一览
    handleCurrentChanges(val) {
      this.pageParam.pageNum = val
      this.domGinsengPage()
    },
    // 分页活动选择智参中心数据一览
    handleSize(val) {
      this.pageParam.pageSize = val
      this.wisdomGinsengPage()
    },
    // 分页活动选择智参中心数据一览
    handleCurrent(val) {
      this.pageParam.pageNum = val
      this.wisdomGinsengPage()
    },
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.pageParam.province == o.name) {
          this.pageParam.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }

          console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    //设置智参
    SetGeneral() {
      if (this.ids.length > 0) {
        this.dialogVisible = true
        this.domGinsengPage()
        this.incity()
      } else {
        this.$message.warning('请选择活动')
      }
    },
    //下架智参中心
    isShelfone(row, index) {
      if (index) {
        this.idgisen = [row.schemaName]
      }
      const query = {
        activityId: row.activityId,
        schemaNames: this.idgisen
      }
      if (this.idgisen.length > 0) {
        const that = this
        this.$alert('是否确认下架？')
          .then(function () {
            return wisdomGinsengOffSelf(query)
          })
          .then(() => {
            that.ids = []
            that.checkAll = false
            that.wisdomGinsengPage()
            that.idgisen = []
            this.$refs.multipleTables.clearSelection()
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择智参中心')
      }
    },
    //上架智参中心
    isShelfzone(row, index) {
      // this.pageParam.id是在点击配置智参时带过来的id
      const id = row.activityId ? row.activityId : this.pageParam.id
      if (index) {
        this.idgisen = [row.schemaName]
      }
      const query = {
        activityId: id,
        schemaNames: this.idgisen
      }
      if (this.idgisen.length > 0) {
        const that = this
        this.$alert('是否确认上架？')
          .then(function () {
            return wisdomGinsengOnSelf(query)
          })
          .then(() => {
            that.ids = []
            that.checkAll = false
            that.wisdomGinsengPage()
            that.idgisen = []
            this.$refs.multipleTables.clearSelection()
            this.$message.success('上架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择智参中心')
      }
    },
    //单个下架
    OffShelf(row) {
      const ids = []
      ids.push(row.id)

      this.$alert('是否确认下架活动？')
        .then(function () {
          return updateOffShelf(ids)
        })
        .then(() => {
          this.ids = []
          this.checkAll = false
          this.search()

          this.$message.success('下架成功')
        })
        .catch(() => {})
    },
    //单个上架
    OnShelf(row) {
      const ids = []
      ids.push(row.id)

      this.$alert('是否确认上架活动？')
        .then(function () {
          return updateOnShelf(ids)
        })
        .then(() => {
          this.ids = []
          this.checkAll = false
          this.search()

          this.$message.success('上架成功')
        })
        .catch(() => {})
    },
    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量下架活动？')
          .then(function () {
            return updateOffShelf(that.ids)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择活动')
      }
    },
    //批量上架
    updateOnShelf() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量上架活动？')
          .then(function () {
            return updateOnShelf(that.ids)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.$message.success('上架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择活动')
      }
    },
    //编辑报名
    toPage(type, row) {
      if (type == 'activityEditActivity') {
        this.$router.push({
          path: '/activity/editActivity',
          query: {
            id: row.id,
            headTabActive: 0,
            pageNum: this.queryInfo.pageNum
          }
        })
      } else if (type == 'accountManagement') {
        this.$router.push({
          path: '/activity/accountManagement',
          query: {
            id: row.id,
            pageNum: this.queryInfo.pageNum
          }
        })
      }
    },
    //配置企业
    activityConfig(row, index) {
      this.$router.push({
        path: '/activity/configureEnterprise',
        query: {
          id: row.id,
          type: index
        }
      })
    },
    //标签
    clciklabel(row) {
      this.$router.push({
        path: '/activity/editActivity',
        query: {
          id: row.id,
          headTabActive: 2
        }
      })
    },

    //编辑企业
    editclick(item) {
      this.$router.push({
        name: 'addenterprise',
        query: { id: item.id }
      })
    },
    //勾选
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      console.log(bol, row, 'ssdf', this.ids, this.idsList)
    },
    //冻结活动
    delcompany(row) {
      console.log(row)
      const query = {
        id: row.id
      }
      this.$alert('您是否确认冻结？')
        .then(function () {
          return freezeActivity(query)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活活动
    cancelcompany(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认激活？')
        .then(function () {
          return cancelFreezeActivity(query)
        })
        .then(() => {
          this.search()
          this.$message.success('激活成功')
        })
        .catch(() => {})
    },
    //设置成通用版活动
    checkClick() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认设置成通用活动？')
          .then(function () {
            return updateGeneralVersion(that.ids)
          })
          .then(() => {
            this.search()
            this.$message.success('设置成功')
            this.ids = []
            this.$refs.multipleTable.clearSelection()
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择活动')
      }
    },
    //查看人数
    checkCount(item) {
      this.$router.push({
        name: 'activitycheckCount',
        query: {
          id: item.id,
          pageType: 1
        }
      })
    },
    //分享人数
    expertSharing(item) {
      this.$router.push({
        name: 'activitySharing',
        query: {
          id: item.id,
          pageType: 2
        }
      })
    },
    //已推送企业数
    PushactivityCompany(item) {
      this.$router.push({
        name: 'PushactivityCompany',
        query: {
          id: item.id
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      localStorage.setItem('paginationCurrentPage', val)
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .storeCenter_item_top_right .el-button {
  width: 95px;
}

.label_list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .label {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-right: 15px;
  }
  .acitve_label {
    padding: 0 10px;

    height: 28px;
    color: #146aff;
    background: #cbdeff;
    border-radius: 2px;
  }
}
.Subtag_list {
  padding: 10px;
  min-height: 77px;
  background: #f5f6f7;
  margin-top: 10px;
  display: flex;
  .label_title {
    width: 70px;
    flex-shrink: 0;
    white-space: nowrap;
    // span {
    //   font-family: 'PingFang SC';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 17px;
    //   margin-right: 15px;
    //   color: #7c7f8e;
    // }
  }
  .label_box {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */
      margin-right: 10px;
      color: #1f212b;
      // margin-bottom: 10px;
      height: 20px;
      padding: 5px 10px;
    }
    white-space: pre-wrap;
    .acitve_label {
      // height: 28px;
      color: #146aff;
      background: #cbdeff;
      border-radius: 2px;
    }
  }
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.comble_txt {
  span {
    margin-left: 20px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}
.user {
  .container {
    min-height:  calc(100vh - 150px);
    // overflow: auto;
    
  }
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #f6f7fb;

    margin-top: 16px;
    padding: 0px;
    padding-bottom: 0px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        height: 120.73px;

        .banner_box {
          position: relative;
          width: 175.55px;
          height: 115.73px;
          padding-right: 10px;
          img {
            width: 170.55px;
            height: 117.73px;
            border-radius: 4px;
            margin-right: 18px;
          }
          .activity_state {
            position: absolute;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 34px;
            text-align: center;
            color: #ffffff;
            top: -10px;
            left: -9px;
            width: 78px;
            height: 34px;
            border-radius: 5px 15px 15px 0;
          }
          .not_started {
            background: #4e93fb;
          }
          .have_in_hand {
            background: #ff7d18;
          }
          .closed {
            background: #999999;
          }
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .generalVersion {
            background: #448aff;
            border-radius: 2px;
            padding: 5px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /* identical to box height */
            margin: 0 10px;
            color: #ffffff;
          }
          .mark_box {
            display: flex;
            img {
              width: 14px;
              // height: 14px;
              margin-right: 6px;
              color: #6e6e6e;
            }
            & > div {
              margin-left: 10px;
            }
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;
            margin-bottom: 1px;
            width: 530px;
            span {
              box-sizing: border-box;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
              font-family: '微软雅黑';
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              padding: 1px 6px;
              margin-right: 10px;
              cursor: pointer;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
          .over_text {
            position: relative;
            top: 5px;
            width: 400px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
        .count_box {
          height: 80px;
          width: 80px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          &:nth-of-type(1) {
            border-right: 1px solid #e8e8e8;
          }
          & > div:nth-of-type(1) {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;

            color: #078bff;
          }
          & > div:nth-of-type(2) {
            margin-top: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        .po_imgqr {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
          img {
            width: 50px;
            height: 50px;
          }
        }
        .operation_area {
          margin-top: 30px;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
        }
        .details {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          margin-top: 5px;
        }
      }
      .pore_qr {
        position: relative;
        left: -200px;
        top: -20px;
        z-index: 999999 !important;

        .popover_img {
          display: flex;
          justify-content: center;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .qr_top {
          display: flex;
          justify-content: center;
          color: #4e93fb;
        }
      }
    }
  }
  .edit {
    padding-left: 12px;
    color: #4e93fb;
    cursor: pointer;
  }
  .frozen {
    padding-left: 12px;
    color: #fd5469;
    cursor: pointer;
  }
  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>
expert
